import { GalleryPage } from "templates";

export const Gallery = () => {

  document.title = "Gallery | ADMIN SGLC";

  return (
    <>
      <GalleryPage/>
    </>
  );
};

export default Gallery;
