import { Formik, Form } from "formik";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Input, InputSelect, Loader, Text } from "atoms";
import { createSyllabus } from "redux/actions";
import { upload } from "utils/utilities";
import { useState } from "react";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),
  type: Yup.string()
    .required("Type Required"),  
});

export const SyllabusForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
    
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      if(values.type === "file" && !values.file) return toast.error("File Required");
      if(values.type === "link" && !values.url) return toast.error("URL Required");
      let url;
      setLocalLoading(true);
      if(values.type === "file") url = await upload(values.file);
      else url = values.url;
      const form = {
        title: values.title,
        href: url,
      }      
      setLocalLoading(false);      
      dispatch(createSyllabus(form));      
    }else if(type === "update"){      
    }        
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <> 
      {localLoading && <Loader/>}     
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>             
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            type: data?.type || "",
            url: data?.href || "",
            file: null,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>                
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box width="100%">
                    <Input   
                      label="Title"
                      name="title"
                      type="text"
                      placeholder="Title*"                  
                      id="title"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.title ? "danger.100" : "primary.500"}
                      touched={touched.title}
                      errors={errors.title}                        
                    /> 
                  </Box>
                  <InputSelect
                    placeholder="Select Type"                    
                    name="type"
                    id="type"
                    options={[
                      { value: "file", label: "File" },
                      { value: "link", label: "Link" },                      
                    ]}
                    value={values.type}
                    setValue={setFieldValue}
                    fieldName="type"
                    touched={touched.type}
                    errors={errors.type}  
                    formik                  
                  />
                </Flex>     
                {values.type === "file" && <Box>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={(e) => setFieldValue("file", e.target.files[0])}                    
                  />
                </Box>}  
                {values.type === "link" && <Box>
                  <Input
                    label="URL"
                    name="url"
                    type="text"
                    placeholder="URL*"
                    id="url"
                    icon={<HiOutlineExternalLink />}
                    iconColor={errors.url ? "danger.100" : "primary.500"}
                    touched={touched.url}
                    errors={errors.url}                                      
                  />
                </Box>}                                                                                         
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}