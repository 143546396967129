import { FieldArray, Formik, Form } from "formik";
import { AiFillEye } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { MdAccessTime, MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Input, Text } from "atoms";
import { createImpLink, createImpUrl, updateImpUrl, hideImpUrl } from "redux/actions";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  index: Yup.number()
    .required("Index Required"),
});

export const ImpLinkForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        title: values.title,
        index: values.index,
      }
      dispatch(createImpLink(form));
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>                          
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            index: data?.index || "",
            importantLinkUrl: data?.importantLinkUrl || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box flexGrow="1">
                    <Input   
                      label="Title"               
                      name="title"
                      type="text"
                      placeholder="Title*"                  
                      id="title"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.title ? "danger.100" : "primary.500"}
                      touched={touched.title}
                      errors={errors.title}                        
                    /> 
                  </Box>
                  <Box flexGrow="1">
                    <Input   
                      label="Index"               
                      name="index"
                      type="number"
                      placeholder="Index*"                  
                      id="index"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.index ? "danger.100" : "primary.500"}
                      touched={touched.index}
                      errors={errors.index}                        
                    /> 
                  </Box>
                  {type==="update" && <Button
                    type="button"
                    variant="secondary"
                    width="fit-content"
                    px="2rem"
                    py="1rem"
                    borderRadius="0.5rem" 
                    onClick={() => {
                      setFieldValue("importantLinkUrl", [...values?.importantLinkUrl, {title: "", href: "", new: true, ext: false, hide: false }])                      
                    }}                   
                  >
                    Add URL
                  </Button>}
                </Flex>
                <FieldArray
                  name="importantLinkUrl"
                  render={(arrayHelpers) => (
                    <Box mt="1rem">
                      {values.importantLinkUrl?.map((item, index) => (
                        <Flex 
                          key={index}
                          alignItems="center"
                          style={{gap: "1rem"}}
                        >    
                          {item.id && <Flex
                            fontSize="2rem"            
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            color={!item.hide ? "success.500" : "danger.500"}              
                            onClick={() => {                              
                              dispatch(hideImpUrl({
                                id: item.id,
                                visibility: !item.hide
                              }))           
                              setFieldValue(`importantLinkUrl.${index}.hide`, !item.hide)                   
                            }}
                          >
                            <AiFillEye />            
                          </Flex>}      
                          <Box>                          
                            <Input
                              label={`URL Title ${index + 1}`}
                              name={`importantLinkUrl.${index}.title`}
                              type="text"
                              placeholder="URL Title"
                              id={`importantLinkUrl.${index}.title`}
                              icon={<MdOutlineTitle />}
                              iconColor={errors.importantLinkUrl ? "danger.100" : "primary.100"}
                              touched={touched.importantLinkUrl}
                              errors={errors.importantLinkUrl}                            
                            />
                          </Box>
                          <Box>
                            <Input
                              label={`URL Link ${index + 1}`}
                              name={`importantLinkUrl.${index}.href`}
                              type="text"
                              placeholder="URL Link"
                              id={`importantLinkUrl.${index}.href`}
                              icon={<MdAccessTime />}
                              iconColor={errors.importantLinkUrl ? "danger.100" : "primary.100"}
                              touched={touched.importantLinkUrl}
                              errors={errors.importantLinkUrl}                            
                            />
                          </Box>                          
                          <Box>                          
                            <FormControlLabel
                              control={
                                <Switch                          
                                  checked={item.new}
                                  onChange={(e) => {
                                    setFieldValue(`importantLinkUrl.${index}.new`, e.target.checked)
                                  }}
                                  name={`importantLinkUrl.${index}.new`}
                                  color="primary"
                                />
                              }
                              label="New Link?"
                            />                  
                          </Box>
                          <Box>                          
                            <FormControlLabel
                              control={
                                <Switch                          
                                  checked={item.ext}
                                  onChange={(e) => {
                                    setFieldValue(`importantLinkUrl.${index}.ext`, e.target.checked)
                                  }}
                                  name={`importantLinkUrl.${index}.ext`}
                                  color="secondary"
                                />
                              }
                              label="External Link?"
                            />                  
                          </Box>
                          {type==="update" && <Button
                            type="button"
                            variant="success"
                            width="fit-content"
                            px="2rem"
                            py="1rem"                            
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"   
                            onClick={() => {
                              if(item.id){
                                dispatch(updateImpUrl({
                                  id: item.id,
                                  title: item.title,
                                  href: item.href,
                                  ext: item.ext,
                                  isNew: item.new,
                                }));                                
                              }else{
                                dispatch(createImpUrl({
                                  id: data.id,
                                  title: item.title,
                                  href: item.href,
                                  ext: item.ext,
                                  isNew: item.new,
                                })); 
                                setData(null);
                                setType("create");
                                resetForm();
                              }
                            }}                            
                          >                           
                            <BsCheckLg/>                           
                          </Button>}
                          {type==="update" && !item.id && <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>}                         
                        </Flex>
                      ))}
                    </Box>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}