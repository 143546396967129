import { ImpLinkPage } from "templates";

const ImpLink = () => {

  document.title = "Important Links | ADMIN SGLC"

  return (
    <ImpLinkPage/>
  )
}

export default ImpLink