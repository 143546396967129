export const appConstants = {  
  GET_ACADAMIC_REQUEST: "GET_ACADAMIC_REQUEST",
  GET_ACADAMIC_SUCCESS: "GET_ACADAMIC_SUCCESS",
  GET_ACADAMIC_FAILURE: "GET_ACADAMIC_FAILURE",
  CREATE_ACADAMIC_REQUEST: "CREATE_ACADAMIC_REQUEST",
  CREATE_ACADAMIC_SUCCESS: "CREATE_ACADAMIC_SUCCESS",
  CREATE_ACADAMIC_FAILURE: "CREATE_ACADAMIC_FAILURE",
  CREATE_ACADAMIC_COURSE_REQUEST: "CREATE_ACADAMIC_COURSE_REQUEST",
  CREATE_ACADAMIC_COURSE_SUCCESS: "CREATE_ACADAMIC_COURSE_SUCCESS",
  CREATE_ACADAMIC_COURSE_FAILURE: "CREATE_ACADAMIC_COURSE_FAILURE",
  UPDATE_ACADAMIC_COURSE_REQUEST: "UPDATE_ACADAMIC_COURSE_REQUEST",
  UPDATE_ACADAMIC_COURSE_SUCCESS: "UPDATE_ACADAMIC_COURSE_SUCCESS",
  UPDATE_ACADAMIC_COURSE_FAILURE: "UPDATE_ACADAMIC_COURSE_FAILURE",
  GET_ADMISSION_REQUEST: "GET_ADMISSION_REQUEST",
  GET_ADMISSION_SUCCESS: "GET_ADMISSION_SUCCESS",
  GET_ADMISSION_FAILURE: "GET_ADMISSION_FAILURE",
  CREATE_ADMISSION_REQUEST: "CREATE_ADMISSION_REQUEST",
  CREATE_ADMISSION_SUCCESS: "CREATE_ADMISSION_SUCCESS",
  CREATE_ADMISSION_FAILURE: "CREATE_ADMISSION_FAILURE",
  UPDATE_ADMISSION_REQUEST: "UPDATE_ADMISSION_REQUEST",
  UPDATE_ADMISSION_SUCCESS: "UPDATE_ADMISSION_SUCCESS",
  UPDATE_ADMISSION_FAILURE: "UPDATE_ADMISSION_FAILURE",
  DELETE_ADMISSION_REQUEST: "DELETE_ADMISSION_REQUEST",
  DELETE_ADMISSION_SUCCESS: "DELETE_ADMISSION_SUCCESS",
  DELETE_ADMISSION_FAILURE: "DELETE_ADMISSION_FAILURE",
  GET_CONFERENCE_REQUEST: "GET_CONFERENCE_REQUEST",
  GET_CONFERENCE_SUCCESS: "GET_CONFERENCE_SUCCESS",
  GET_CONFERENCE_FAILURE: "GET_CONFERENCE_FAILURE",
  CREATE_CONFERENCE_REQUEST: "CREATE_CONFERENCE_REQUEST",
  CREATE_CONFERENCE_SUCCESS: "CREATE_CONFERENCE_SUCCESS",
  CREATE_CONFERENCE_FAILURE: "CREATE_CONFERENCE_FAILURE",
  UPDATE_CONFERENCE_REQUEST: "UPDATE_CONFERENCE_REQUEST",
  UPDATE_CONFERENCE_SUCCESS: "UPDATE_CONFERENCE_SUCCESS",
  UPDATE_CONFERENCE_FAILURE: "UPDATE_CONFERENCE_FAILURE",
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  CREATE_EVENT_REQUEST: "CREATE_EVENT_REQUEST",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILURE: "CREATE_EVENT_FAILURE",
  UPDATE_EVENT_REQUEST: "UPDATE_EVENT_REQUEST",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPDATE_EVENT_FAILURE",
  DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
  GET_EXAM_SCHEDULE_REQUEST: "GET_EXAM_SCHEDULE_REQUEST",
  GET_EXAM_SCHEDULE_SUCCESS: "GET_EXAM_SCHEDULE_SUCCESS",
  GET_EXAM_SCHEDULE_FAILURE: "GET_EXAM_SCHEDULE_FAILURE",
  CREATE_EXAM_SCHEDULE_REQUEST: "CREATE_EXAM_SCHEDULE_REQUEST",
  CREATE_EXAM_SCHEDULE_SUCCESS: "CREATE_EXAM_SCHEDULE_SUCCESS",
  CREATE_EXAM_SCHEDULE_FAILURE: "CREATE_EXAM_SCHEDULE_FAILURE",
  UPDATE_EXAM_SCHEDULE_REQUEST: "UPDATE_EXAM_SCHEDULE_REQUEST",
  UPDATE_EXAM_SCHEDULE_SUCCESS: "UPDATE_EXAM_SCHEDULE_SUCCESS",
  UPDATE_EXAM_SCHEDULE_FAILURE: "UPDATE_EXAM_SCHEDULE_FAILURE",
  DELETE_EXAM_SCHEDULE_REQUEST: "DELETE_EXAM_SCHEDULE_REQUEST",
  DELETE_EXAM_SCHEDULE_SUCCESS: "DELETE_EXAM_SCHEDULE_SUCCESS",
  DELETE_EXAM_SCHEDULE_FAILURE: "DELETE_EXAM_SCHEDULE_FAILURE",
  GET_FACULTY_REQUEST: "GET_FACULTY_REQUEST",
  GET_FACULTY_SUCCESS: "GET_FACULTY_SUCCESS",
  GET_FACULTY_FAILURE: "GET_FACULTY_FAILURE",
  CREATE_FACULTY_REQUEST: "CREATE_FACULTY_REQUEST",
  CREATE_FACULTY_SUCCESS: "CREATE_FACULTY_SUCCESS",
  CREATE_FACULTY_FAILURE: "CREATE_FACULTY_FAILURE",
  UPDATE_FACULTY_REQUEST: "UPDATE_FACULTY_REQUEST",
  UPDATE_FACULTY_SUCCESS: "UPDATE_FACULTY_SUCCESS",
  UPDATE_FACULTY_FAILURE: "UPDATE_FACULTY_FAILURE",
  DELETE_FACULTY_REQUEST: "DELETE_FACULTY_REQUEST",
  DELETE_FACULTY_SUCCESS: "DELETE_FACULTY_SUCCESS",
  DELETE_FACULTY_FAILURE: "DELETE_FACULTY_FAILURE",
  GET_GALLERY_REQUEST: "GET_GALLERY_REQUEST",
  GET_GALLERY_SUCCESS: "GET_GALLERY_SUCCESS",
  GET_GALLERY_FAILURE: "GET_GALLERY_FAILURE",
  CREATE_GALLERY_REQUEST: "CREATE_GALLERY_REQUEST",
  CREATE_GALLERY_SUCCESS: "CREATE_GALLERY_SUCCESS",
  CREATE_GALLERY_FAILURE: "CREATE_GALLERY_FAILURE",
  UPDATE_GALLERY_REQUEST: "UPDATE_GALLERY_REQUEST",
  UPDATE_GALLERY_SUCCESS: "UPDATE_GALLERY_SUCCESS",
  UPDATE_GALLERY_FAILURE: "UPDATE_GALLERY_FAILURE",
  GET_IMP_LINKS_REQUEST: "GET_IMP_LINKS_REQUEST",
  GET_IMP_LINKS_SUCCESS: "GET_IMP_LINKS_SUCCESS",
  GET_IMP_LINKS_FAILURE: "GET_IMP_LINKS_FAILURE",
  CREATE_IMP_LINKS_REQUEST: "CREATE_IMP_LINKS_REQUEST",
  CREATE_IMP_LINKS_SUCCESS: "CREATE_IMP_LINKS_SUCCESS",
  CREATE_IMP_LINKS_FAILURE: "CREATE_IMP_LINKS_FAILURE",
  CREATE_IMP_URL_REQUEST: "CREATE_IMP_URL_REQUEST",
  CREATE_IMP_URL_SUCCESS: "CREATE_IMP_URL_SUCCESS",
  CREATE_IMP_URL_FAILURE: "CREATE_IMP_URL_FAILURE",
  UPDATE_IMP_URL_REQUEST: "UPDATE_IMP_URL_REQUEST",
  UPDATE_IMP_URL_SUCCESS: "UPDATE_IMP_URL_SUCCESS",
  UPDATE_IMP_URL_FAILURE: "UPDATE_IMP_URL_FAILURE",
  HIDE_IMP_URL_REQUEST: "HIDE_IMP_URL_REQUEST",
  HIDE_IMP_URL_SUCCESS: "HIDE_IMP_URL_SUCCESS",
  HIDE_IMP_URL_FAILURE: "HIDE_IMP_URL_FAILURE",
  GET_NEWS_REQUEST: "GET_NEWS_REQUEST",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",
  CREATE_NEWS_REQUEST: "CREATE_NEWS_REQUEST",
  CREATE_NEWS_SUCCESS: "CREATE_NEWS_SUCCESS",
  CREATE_NEWS_FAILURE: "CREATE_NEWS_FAILURE",
  UPDATE_NEWS_REQUEST: "UPDATE_NEWS_REQUEST",
  UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
  UPDATE_NEWS_FAILURE: "UPDATE_NEWS_FAILURE",
  DELETE_NEWS_REQUEST: "DELETE_NEWS_REQUEST",
  DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
  DELETE_NEWS_FAILURE: "DELETE_NEWS_FAILURE",
  GET_RESULT_REQUEST: "GET_RESULT_REQUEST",
  GET_RESULT_SUCCESS: "GET_RESULT_SUCCESS",
  GET_RESULT_FAILURE: "GET_RESULT_FAILURE",
  CREATE_RESULT_REQUEST: "CREATE_RESULT_REQUEST",
  CREATE_RESULT_SUCCESS: "CREATE_RESULT_SUCCESS",
  CREATE_RESULT_FAILURE: "CREATE_RESULT_FAILURE",
  UPDATE_RESULT_REQUEST: "UPDATE_RESULT_REQUEST",
  UPDATE_RESULT_SUCCESS: "UPDATE_RESULT_SUCCESS",
  UPDATE_RESULT_FAILURE: "UPDATE_RESULT_FAILURE",
  DELETE_RESULT_REQUEST: "DELETE_RESULT_REQUEST",
  DELETE_RESULT_SUCCESS: "DELETE_RESULT_SUCCESS",
  DELETE_RESULT_FAILURE: "DELETE_RESULT_FAILURE",
  GET_SYLLABUS_REQUEST: "GET_SYLLABUS_REQUEST",
  GET_SYLLABUS_SUCCESS: "GET_SYLLABUS_SUCCESS",
  GET_SYLLABUS_FAILURE: "GET_SYLLABUS_FAILURE",
  CREATE_SYLLABUS_REQUEST: "CREATE_SYLLABUS_REQUEST",
  CREATE_SYLLABUS_SUCCESS: "CREATE_SYLLABUS_SUCCESS",
  CREATE_SYLLABUS_FAILURE: "CREATE_SYLLABUS_FAILURE",
  UPDATE_SYLLABUS_REQUEST: "UPDATE_SYLLABUS_REQUEST",
  UPDATE_SYLLABUS_SUCCESS: "UPDATE_SYLLABUS_SUCCESS",
  UPDATE_SYLLABUS_FAILURE: "UPDATE_SYLLABUS_FAILURE",
  DELETE_SYLLABUS_REQUEST: "DELETE_SYLLABUS_REQUEST",
  DELETE_SYLLABUS_SUCCESS: "DELETE_SYLLABUS_SUCCESS",
  DELETE_SYLLABUS_FAILURE: "DELETE_SYLLABUS_FAILURE",
  GET_REVIEW_REQUEST: "GET_REVIEW_REQUEST",
  GET_REVIEW_SUCCESS: "GET_REVIEW_SUCCESS",
  GET_REVIEW_FAILURE: "GET_REVIEW_FAILURE",
  CREATE_REVIEW_REQUEST: "CREATE_REVIEW_REQUEST",
  CREATE_REVIEW_SUCCESS: "CREATE_REVIEW_SUCCESS",
  CREATE_REVIEW_FAILURE: "CREATE_REVIEW_FAILURE",
  UPDATE_REVIEW_REQUEST: "UPDATE_REVIEW_REQUEST",
  UPDATE_REVIEW_SUCCESS: "UPDATE_REVIEW_SUCCESS",
  UPDATE_REVIEW_FAILURE: "UPDATE_REVIEW_FAILURE",
  RESET_APP: "RESET_APP",
  RESET: "RESET",
}

export const userConstants = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",  
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",  
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  RESET_USER: "RESET_USER",
  RESET: "RESET"
}