import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { ReviewForm } from "molecules";
import { getReviews, updateReview } from "redux/actions";

export const ReviewPage = () => {

  const [ page, setPage ] = useState(1);
  const [ username, setUsername ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { testimonial } = useSelector(state => state.app);

  useEffect(()=>{
    if(username) dispatch(getReviews(page, username))
  }, [page, username]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (                    
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color={params.row.hide ? "danger.500" : "success.500"}
            onClick={() => {                
              dispatch(updateReview({
                id: params.row.id,
                visibility: !params.row.hide
              }))
            }}
          >
            <AiFillEye />            
          </Flex>                    
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "username", headerName: "User Name", minWidth: 200, flex: 1},
    {field: "review", headerName: "Review", minWidth: 200, flex: 1},    
  ]

  const rows = [];
  testimonial?.testimonials?.forEach(r => {    
    rows.push({
      id: r.id,
      username: r.username,
      review: r.review,
      hide: r.hide,      
    });
  });

  return (
    <Box>       
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Reviews
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={username}
            icon={<BiSearchAlt2/>}
            setValue={setUsername}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <ReviewForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box> 
      {testimonial?.resultPerPage < testimonial?.totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={testimonial?.resultPerPage}
          totalItemsCount={testimonial?.totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {testimonial?.totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {testimonial?.totalCount}
      </Text>}       
  </Box>
  )
}