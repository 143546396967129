import { FieldArray, Formik, Form } from "formik";
import { BsCheckLg } from "react-icons/bs";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { MdAccessTime, MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Input, Text } from "atoms";
import { createAcademic, createAcademicCourse, updateAcademicCourse } from "redux/actions";

const validationSchema = Yup.object({
  name: Yup.string()    
    .required("Name Required"),      
});

export const AcademicForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        name: values.name,
      }
      dispatch(createAcademic(form));
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>                          
        {!fold && <Formik
          initialValues={{
            name: type === "update" ? data?.name : "",
            academicCourses: type === "update" ? data?.academicCourses : [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box flexGrow="1">
                    <Input   
                      label="Name"               
                      name="name"
                      type="text"
                      placeholder="Name*"                  
                      id="name"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.name ? "danger.100" : "primary.500"}
                      touched={touched.name}
                      errors={errors.name}                        
                    /> 
                  </Box>
                  {type==="update" && <Button
                    type="button"
                    variant="secondary"
                    width="fit-content"
                    px="2rem"
                    py="1rem"
                    borderRadius="0.5rem" 
                    onClick={() => {
                      setFieldValue("academicCourses", [...values?.academicCourses, {name: "", duration: "", eligibility: "" }])                      
                    }}                   
                  >
                    Add Course
                  </Button>}
                </Flex>
                <FieldArray
                  name="academicCourses"
                  render={(arrayHelpers) => (
                    <Box mt="1rem">
                      {values.academicCourses?.map((item, index) => (
                        <Flex 
                          key={index}
                          alignItems="center"
                          style={{gap: "1rem"}}
                        >    
                          <Box>                          
                            <Input
                              label={`Course Name ${index + 1}`}
                              name={`academicCourses.${index}.name`}
                              type="text"
                              placeholder="Course Name"
                              id={`academicCourses.${index}.name`}
                              icon={<MdOutlineTitle />}
                              iconColor={errors.academicCourses ? "danger.100" : "primary.100"}
                              touched={touched.academicCourses}
                              errors={errors.academicCourses}                            
                            />
                          </Box>
                          <Box>
                            <Input
                              label={`Course Duration ${index + 1}`}
                              name={`academicCourses.${index}.duration`}
                              type="text"
                              placeholder="Course Duration"
                              id={`academicCourses.${index}.duration`}
                              icon={<MdAccessTime />}
                              iconColor={errors.academicCourses ? "danger.100" : "primary.100"}
                              touched={touched.academicCourses}
                              errors={errors.academicCourses}                            
                            />
                          </Box>
                          <Box>
                            <Input
                              label={`Course Eligibility ${index + 1}`}
                              name={`academicCourses.${index}.eligibility`}
                              type="text"
                              placeholder="Course Eligibility"
                              id={`academicCourses.${index}.eligibility`}
                              icon={<HiOutlineLightningBolt />}
                              iconColor={errors.academicCourses ? "danger.100" : "primary.100"}
                              touched={touched.academicCourses}
                              errors={errors.academicCourses}                            
                            />
                          </Box>
                          {type==="update" && <Button
                            type="button"
                            variant="success"
                            width="fit-content"
                            px="2rem"
                            py="1rem"                            
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"   
                            onClick={() => {
                              if(item.id){
                                dispatch(updateAcademicCourse({
                                  id: item.id,
                                  name: item.name,
                                  eligibility: item.eligibility,
                                  duration: item.duration,
                                  aId: data.id,
                                }));                                
                              }else{
                                dispatch(createAcademicCourse({
                                  id: item.id,
                                  name: item.name,
                                  eligibility: item.eligibility,
                                  duration: item.duration,
                                  aId: data.id,
                                })); 
                                setData(null);
                                setType("create");
                                resetForm();
                              }
                            }}                            
                          >                           
                            <BsCheckLg/>                           
                          </Button>}
                          {type==="update" && !item.id && <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>}                         
                        </Flex>
                      ))}
                    </Box>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}