import { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { AcademicForm } from "molecules";
import { getAcademics } from "redux/actions";

export const AcademicPage = () => {

  const [ name, setName ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);
  
  const dispatch = useDispatch();
  const { academic } = useSelector(state => state.app);

  useEffect(()=>{
    if(name) dispatch(getAcademics(name))
  }, [name]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color="warning.500"
            onClick={() => {
              setType("update");
              setData(academic?.find(a => a.id === params.row.id));
              setFold(false);
            }}
          >
            <MdModeEditOutline />            
          </Flex>
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "name", headerName: "Name", minWidth: 200, flex: 1},
    { field: "courses", headerName: "Courses", minWidth: 200, flex: 1 },    
  ]

  const rows = [];
  academic?.forEach(a => {    
    rows.push({
      id: a.id,
      name: a.name,      
      courses: a?.academicCourses?.map(c => c.name).join(", "),      
    });
  });

  return (
    <Box>    
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Academics
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={name}
            icon={<BiSearchAlt2/>}
            setValue={setName}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <AcademicForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box>      
  </Box>
  )
}