import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,  
  academic: null,
  admission: null,
  conference: {},
  event: {},
  examSchedule: {},
  faculty: null,
  gallery: {},
  impLinks: null,
  news: {},
  result: {},
  syllabus: {},
  testimonial: {},
}

export const appReducer = (state = initState, action) => {
  let temp = null;
  let index = 0;
  switch(action.type){
    case appConstants.GET_ACADAMIC_REQUEST:
    case appConstants.CREATE_ACADAMIC_REQUEST:
    case appConstants.CREATE_ACADAMIC_COURSE_REQUEST:
    case appConstants.UPDATE_ACADAMIC_COURSE_REQUEST:
    case appConstants.GET_ADMISSION_REQUEST:
    case appConstants.CREATE_ADMISSION_REQUEST:
    case appConstants.UPDATE_ADMISSION_REQUEST:
    case appConstants.DELETE_ADMISSION_REQUEST:
    case appConstants.GET_CONFERENCE_REQUEST:
    case appConstants.CREATE_CONFERENCE_REQUEST:
    case appConstants.UPDATE_CONFERENCE_REQUEST:
    case appConstants.GET_EVENT_REQUEST:
    case appConstants.CREATE_EVENT_REQUEST:
    case appConstants.UPDATE_EVENT_REQUEST:
    case appConstants.DELETE_EVENT_REQUEST:
    case appConstants.GET_EXAM_SCHEDULE_REQUEST:
    case appConstants.CREATE_EXAM_SCHEDULE_REQUEST:
    case appConstants.UPDATE_EXAM_SCHEDULE_REQUEST:
    case appConstants.DELETE_EXAM_SCHEDULE_REQUEST:
    case appConstants.GET_FACULTY_REQUEST:
    case appConstants.CREATE_FACULTY_REQUEST:
    case appConstants.UPDATE_FACULTY_REQUEST:
    case appConstants.DELETE_FACULTY_REQUEST:
    case appConstants.GET_GALLERY_REQUEST:
    case appConstants.CREATE_GALLERY_REQUEST:
    case appConstants.UPDATE_GALLERY_REQUEST:
    case appConstants.GET_IMP_LINKS_REQUEST:
    case appConstants.CREATE_IMP_LINKS_REQUEST:
    case appConstants.CREATE_IMP_URL_REQUEST:
    case appConstants.UPDATE_IMP_URL_REQUEST:
    case appConstants.HIDE_IMP_URL_REQUEST:
    case appConstants.GET_NEWS_REQUEST:
    case appConstants.CREATE_NEWS_REQUEST:
    case appConstants.UPDATE_NEWS_REQUEST:
    case appConstants.DELETE_NEWS_REQUEST:
    case appConstants.GET_RESULT_REQUEST:
    case appConstants.CREATE_RESULT_REQUEST:
    case appConstants.UPDATE_RESULT_REQUEST:
    case appConstants.DELETE_RESULT_REQUEST:
    case appConstants.GET_SYLLABUS_REQUEST:
    case appConstants.CREATE_SYLLABUS_REQUEST:
    case appConstants.UPDATE_SYLLABUS_REQUEST:
    case appConstants.DELETE_SYLLABUS_REQUEST:
    case appConstants.GET_REVIEW_REQUEST:
    case appConstants.CREATE_REVIEW_REQUEST:
    case appConstants.UPDATE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      }
    
    case appConstants.GET_ACADAMIC_SUCCESS:
      return{
        ...state,
        academic: action.payload,
        loading: false,
      }

    case appConstants.CREATE_ACADAMIC_SUCCESS:      
      return{
        ...state,
        academic: [action.payload.data, ...state?.academic],
        message: action.payload.message,
        loading: false,
      }

    case appConstants.CREATE_ACADAMIC_COURSE_SUCCESS:      
      temp = state.academic
      index = temp?.findIndex(a => a.id === action.payload.data?.id);
      temp[index] = action.payload.data
      return{
        ...state,
        academic: temp,
        message: action.payload.message,
        loading: false,
      }
    
    case appConstants.UPDATE_ACADAMIC_COURSE_SUCCESS:
      temp = state.academic
      index = temp?.findIndex(a => a.id === action.payload.data?.academicTypeId);
      temp[index].academicCourses = temp[index].academicCourses?.map(ac => ac.id === action.payload.data.id ? action.payload.data : ac)
      return{
        ...state,
        academic: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_ADMISSION_SUCCESS:
      return{
        ...state,
        admission: action.payload,
        loading: false,        
      }

    case appConstants.CREATE_ADMISSION_SUCCESS:
      return{
        ...state,
        admission: [action.payload.data, ...state?.admission],
        message: action.payload.message,
        loading: false,        
      }
      
    case appConstants.UPDATE_ADMISSION_SUCCESS:
      return{
        ...state,
        admission: state.admission?.map(a => a.id === action.payload.data.id ? action.payload.data : a),
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_ADMISSION_SUCCESS:
      return{
        ...state,
        admission: state.admission?.filter(a => a.id !== action.payload.data.id),
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_CONFERENCE_SUCCESS:
      return{
        ...state,
        conference: action.payload,
        loading: false,
      }

    case appConstants.CREATE_CONFERENCE_SUCCESS:
      temp = state.conference
      if(temp?.cw?.length > 0){
        temp.cw = [action.payload.data, ...temp.cw]
      }else{
        temp["cw"] = [action.payload.data]
      }
      return{
        ...state,
        conference: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_CONFERENCE_SUCCESS:
      temp = state.conference
      for(let i = 0; i < temp?.cw?.length; i++){
        if(temp?.cw[i].id === action.payload.data.id){
          temp.cw[i] = action.payload.data
          break;
        }
      }      
      return{
        ...state,
        conference: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_EVENT_SUCCESS:
      return{
        ...state,
        event: action.payload,
        loading: false,
      }

    case appConstants.CREATE_EVENT_SUCCESS: 
      temp = state.event
      if(temp?.events?.length > 0){
        temp.events = [action.payload.data, ...temp.events]
      }else{
        temp["events"] = [action.payload.data]
      }
      return{
        ...state,
        event: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_EVENT_SUCCESS:
      temp = state.event
      for(let i = 0; i < temp?.events?.length; i++){
        if(temp?.events[i].id === action.payload.data.id){
          temp.events[i] = action.payload.data
          break;
        }
      }

      return{
        ...state,
        event: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_EVENT_SUCCESS:
      temp = state.event
      for(let i = 0; i < temp?.events?.length; i++){
        if(temp?.events[i].id === action.payload.data.id){
          temp.events.splice(i, 1)
          break;
        }
      }

      return{
        ...state,
        event: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_EXAM_SCHEDULE_SUCCESS:
      return{
        ...state,
        examSchedule: action.payload,
        loading: false,
      }

    case appConstants.CREATE_EXAM_SCHEDULE_SUCCESS:
      temp = state.examSchedule
      if(temp?.examSchedule?.length > 0){
        temp.examSchedule = [action.payload.data, ...temp.examSchedule]
      }else{
        temp["examSchedule"] = [action.payload.data]
      }
      return{
        ...state,
        examSchedule: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_EXAM_SCHEDULE_SUCCESS:
      temp = state.examSchedule
      for(let i = 0; i < temp?.examSchedule?.length; i++){
        if(temp?.examSchedule[i].id === action.payload.data.id){
          temp.examSchedule[i] = action.payload.data
          break;
        }
      }
      return{
        ...state,
        examSchedule: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_EXAM_SCHEDULE_SUCCESS:
      temp = state.examSchedule
      for(let i = 0; i < temp?.examSchedule?.length; i++){
        if(temp?.examSchedule[i].id === action.payload.data.id){
          temp.examSchedule.splice(i, 1)
          break;
        }
      }

      return{
        ...state,
        examSchedule: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_FACULTY_SUCCESS:
      return{
        ...state,
        faculty: action.payload,
        loading: false,
      }

    case appConstants.CREATE_FACULTY_SUCCESS:
      return{
        ...state,
        faculty: [action.payload.data, ...state?.faculty],
        message: action.payload.message,
        loading: false,
      }
      
    case appConstants.UPDATE_FACULTY_SUCCESS:
      return{
        ...state,
        faculty: state.faculty?.map(f => f.id === action.payload.data.id ? action.payload.data : f),
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_FACULTY_SUCCESS:
      return{
        ...state,
        faculty: state.faculty?.filter(f => f.id !== action.payload.data.id),
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_GALLERY_SUCCESS:
      return{
        ...state,
        gallery: action.payload,
        loading: false,
      }

    case appConstants.CREATE_GALLERY_SUCCESS:
      temp = state.gallery
      if(temp?.albums?.length > 0){
        temp.albums = [action.payload.data, ...temp.albums]
      }else{
        temp["albums"] = [action.payload.data]
      }
      return{
        ...state,
        gallery: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_GALLERY_SUCCESS:
      temp = state.gallery
      for(let i = 0; i < temp?.albums?.length; i++){
        if(temp?.albums[i].id === action.payload.data.id){
          temp.albums[i] = action.payload.data
          break;
        }
      }      
      return{
        ...state,
        gallery: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_IMP_LINKS_SUCCESS:
      return{
        ...state,
        impLinks: action.payload,
        loading: false,
      }

    case appConstants.CREATE_IMP_LINKS_SUCCESS:      
      return{
        ...state,
        impLinks: [action.payload.data, ...state?.impLinks],
        message: action.payload.message,
        loading: false,
      }

    case appConstants.CREATE_IMP_URL_SUCCESS:
      temp = state.impLinks
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.id){
          temp[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        impLinks: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_IMP_URL_SUCCESS:
      temp = state.impLinks
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.importantLinkId){
          for(let j = 0; j < temp[i].importantLinkUrl.length; j++){
            if(temp[i].importantLinkUrl[j].id === action.payload.data.id){
              temp[i].importantLinkUrl[j] = action.payload.data
              break
            }
          }
          break
        }
      }      

      return{
        ...state,
        impLinks: temp,
        message: action.payload.message,
        loading: false,       
      }

    case appConstants.HIDE_IMP_URL_SUCCESS:
      temp = state.impLinks
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.importantLinkId){
          for(let j = 0; j < temp[i].importantLinkUrl.length; j++){
            if(temp[i].importantLinkUrl[j].id === action.payload.data.id){
              temp[i].importantLinkUrl[j] = action.payload.data
              break
            }
          }
          break
        }
      }
      return{
        ...state,
        impLinks: temp,
        message: action.payload.message,
        loading: false,       
      }

    case appConstants.GET_NEWS_SUCCESS:
      return{
        ...state,
        news: action.payload,
        loading: false,
      }

    case appConstants.CREATE_NEWS_SUCCESS:
      temp = state.news
      if(temp?.news?.length > 0){
        temp.news = [action.payload.data, ...temp.news]
      }else{
        temp["news"] = [action.payload.data]
      }
      return{
        ...state,
        news: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_NEWS_SUCCESS:
      temp = state.news
      for(let i = 0; i < temp?.news?.length; i++){
        if(temp?.news[i].id === action.payload.data.id){
          temp.news[i] = action.payload.data
          break;
        }
      }
      return{
        ...state,
        news: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_NEWS_SUCCESS:
      temp = state.news
      for(let i = 0; i < temp?.news?.length; i++){
        if(temp?.news[i].id === action.payload.data.id){
          temp.news.splice(i, 1)
          break;
        }
      }
      return{
        ...state,
        news: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_RESULT_SUCCESS:
      return{
        ...state,
        result: action.payload,
        loading: false,        
      }

    case appConstants.CREATE_RESULT_SUCCESS:
      temp = state.result
      if(temp?.results?.length > 0){
        temp.results = [action.payload.data, ...temp.results]
      }else{
        temp["results"] = [action.payload.data]
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }
      
    case appConstants.UPDATE_RESULT_SUCCESS:
      temp = state.result
      for(let i = 0; i < temp?.results?.length; i++){
        if(temp.results[i].id === action.payload.data.id){
          temp.results[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_RESULT_SUCCESS:
      temp = state.result
      for(let i = 0; i < temp?.results?.length; i++){
        if(temp.results[i].id === action.payload.data.id){
          temp.results.splice(i, 1)
          break
        }
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_SYLLABUS_SUCCESS:
      return{
        ...state,
        syllabus: action.payload,
        loading: false,
      }

    case appConstants.CREATE_SYLLABUS_SUCCESS:
      temp = state.syllabus
      if(temp?.syllabus?.length > 0){
        temp.syllabus = [action.payload.data, ...temp.syllabus]
      }else{
        temp["syllabus"] = [action.payload.data]
      }
      return{
        ...state,
        syllabus: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_SYLLABUS_SUCCESS:
      temp = state.syllabus
      for(let i = 0; i < temp?.syllabus?.length; i++){
        if(temp.syllabus[i].id === action.payload.data.id){
          temp.syllabus[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        syllabus: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_SYLLABUS_SUCCESS:
      temp = state.syllabus
      for(let i = 0; i < temp?.syllabus?.length; i++){
        if(temp.syllabus[i].id === action.payload.data.id){
          temp.syllabus.splice(i, 1)
          break
        }
      }
      return{
        ...state,
        syllabus: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_REVIEW_SUCCESS:
      return{
        ...state,
        testimonial: action.payload,
        loading: false,
      }

    case appConstants.CREATE_REVIEW_SUCCESS:
      temp = state.testimonial
      if(temp?.testimonials?.length > 0){
        temp.testimonials = [action.payload.data, ...temp.testimonials]
      }else{
        temp["testimonials"] = [action.payload.data]
      }
      return{
        ...state,
        testimonial: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_REVIEW_SUCCESS:
      temp = state.testimonial
      for(let i = 0; i < temp?.testimonials?.length; i++){
        if(temp.testimonials[i].id === action.payload.data.id){
          temp.testimonials[i] = action.payload.data
          break
        }
      }      
      return{
        ...state,
        testimonial: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_ACADAMIC_FAILURE:
    case appConstants.CREATE_ACADAMIC_FAILURE:
    case appConstants.CREATE_ACADAMIC_COURSE_FAILURE:
    case appConstants.UPDATE_ACADAMIC_COURSE_FAILURE:
    case appConstants.GET_ADMISSION_FAILURE:
    case appConstants.CREATE_ADMISSION_FAILURE:
    case appConstants.UPDATE_ADMISSION_FAILURE:
    case appConstants.DELETE_ADMISSION_FAILURE:
    case appConstants.GET_CONFERENCE_FAILURE:
    case appConstants.CREATE_CONFERENCE_FAILURE:
    case appConstants.UPDATE_CONFERENCE_FAILURE:
    case appConstants.GET_EVENT_FAILURE:
    case appConstants.CREATE_EVENT_FAILURE:
    case appConstants.UPDATE_EVENT_FAILURE:
    case appConstants.DELETE_EVENT_FAILURE:
    case appConstants.GET_EXAM_SCHEDULE_FAILURE:
    case appConstants.CREATE_EXAM_SCHEDULE_FAILURE:
    case appConstants.UPDATE_EXAM_SCHEDULE_FAILURE:
    case appConstants.DELETE_EXAM_SCHEDULE_FAILURE:
    case appConstants.GET_FACULTY_FAILURE:
    case appConstants.CREATE_FACULTY_FAILURE:
    case appConstants.UPDATE_FACULTY_FAILURE:
    case appConstants.DELETE_FACULTY_FAILURE:
    case appConstants.GET_GALLERY_FAILURE:
    case appConstants.CREATE_GALLERY_FAILURE:
    case appConstants.UPDATE_GALLERY_FAILURE:
    case appConstants.GET_IMP_LINKS_FAILURE:
    case appConstants.CREATE_IMP_LINKS_FAILURE:
    case appConstants.CREATE_IMP_URL_FAILURE:
    case appConstants.UPDATE_IMP_URL_FAILURE:
    case appConstants.HIDE_IMP_URL_FAILURE:
    case appConstants.GET_NEWS_FAILURE:
    case appConstants.CREATE_NEWS_FAILURE:
    case appConstants.UPDATE_NEWS_FAILURE:
    case appConstants.DELETE_NEWS_FAILURE:
    case appConstants.GET_RESULT_FAILURE:
    case appConstants.CREATE_RESULT_FAILURE:
    case appConstants.UPDATE_RESULT_FAILURE:
    case appConstants.DELETE_RESULT_FAILURE:
    case appConstants.GET_SYLLABUS_FAILURE:
    case appConstants.CREATE_SYLLABUS_FAILURE:
    case appConstants.UPDATE_SYLLABUS_FAILURE:
    case appConstants.DELETE_SYLLABUS_FAILURE:
    case appConstants.GET_REVIEW_FAILURE:
    case appConstants.CREATE_REVIEW_FAILURE:
    case appConstants.UPDATE_REVIEW_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case appConstants.RESET_APP:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,
      }

    case appConstants.RESET:
      return initState

    default: return state;
  }
}