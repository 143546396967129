import { CWPage } from "templates";

export const CW = () => {

  document.title = "Conference & Workshop | ADMIN SGLC";

  return (
    <>
      <CWPage/>
    </>
  );
};

export default CW;
