import { AcademicPage } from "templates";

export const Academic = () => {

  document.title = "Academic | ADMIN SGLC";

  return (
    <>
      <AcademicPage/>
    </>
  );
};

export default Academic;
