import { useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { AdmissionForm, DeleteConfirmationModal } from "molecules";
import { getAdmissions, deleteAdmission } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

export const AdmissionPage = () => {

  const [ title, setTitle ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  
  const dispatch = useDispatch();
  const { admission } = useSelector(state => state.app);

  useEffect(()=>{
    if(title) dispatch(getAdmissions(title))
  }, [title]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (
          <Flex
            style={{gap: "1rem"}}
          >
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color="warning.500"
              onClick={() => {                
                setType("update");
                setData(admission?.find(a => a.id === params.row.id));
                setFold(false);
              }}
            >
              <MdModeEditOutline />            
            </Flex>
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color="danger.500"
              onClick={() => {
                setForDelete({
                  id: params.row.id,
                  name: params.row.title
                });
                setDeleteModalOpen(true);
              }}
            >
              <MdDeleteOutline />            
            </Flex>
          </Flex>          
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },
    { field: "duration", headerName: "Duration", minWidth: 200, flex: 1 },    
    { field: "eligibility", headerName: "Eligibility", minWidth: 200, flex: 1},      
    { field: "startDate", headerName: "Start Date", minWidth: 200, flex: 1 },  
    { field: "endDate", headerName: "End Date", minWidth: 200, flex: 1 },      
    {field: "active", headerName: "Active", minWidth: 130, flex: 0.5, align: "center", headerAlign: "center", disableColumnMenu: true, sortable: false,
      renderCell: (params) => (
        <Box
          fontSize="1.8rem"
          color={params.row.active ? "success.500" : "danger.500"}
        >
          {params.row.active ? <AiFillCheckCircle/> : <AiFillCloseCircle/>}
        </Box>
      )
    },
  ]

  const rows = [];
  admission?.forEach(a => {    
    rows.push({
      id: a.id,
      title: a.title,
      duration: a.duration,
      eligibility: a.eligibility,
      startDate: isoDateToHtmlInputDate(a.startDate),
      endDate: isoDateToHtmlInputDate(a.lastDate),
      active: !a.hide
    });
  });

  return (
    <Box> 
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name,
            module: "Admission Form",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => {
            dispatch(deleteAdmission(forDelete.id))
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
        />
      }   
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Admissions
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={title}
            icon={<BiSearchAlt2/>}
            setValue={setTitle}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <AdmissionForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box>      
  </Box>
  )
}