import { useEffect, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { ImpLinkForm } from "molecules";
import { getImpLinks } from "redux/actions";

export const ImpLinkPage = () => {
  
  const [ title, setTitle ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { impLinks } = useSelector(state => state.app);

  useEffect(()=>{
    if(title) dispatch(getImpLinks(title))
  }, [title]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (                   
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color="warning.500"
            onClick={() => {                
              setType("update");
              setData(impLinks?.find(i => i.id === params.row.id));
              setFold(false);
            }}
          >
            <MdModeEdit />            
          </Flex>                              
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "title", headerName: "Title", minWidth: 200, flex: 1},
    {field: "index", headerName: "Index", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  impLinks?.forEach(i => {    
    rows.push({
      id: i.id,
      title: i.title,
      hide: i.hide,
      index: i.index,     
    });
  });

  return (
    <Box>         
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Important Links
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={title}
            icon={<BiSearchAlt2/>}
            setValue={setTitle}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <ImpLinkForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box>          
  </Box>
  )
}