import { ResultPage } from "templates";

export const Result = () => {

  document.title = "Results | ADMIN SGLC";

  return (
    <>
      <ResultPage/>
    </>
  );
};

export default Result;
