import { AdmissionPage } from "templates";

export const Admission = () => {

  document.title = "Admission | ADMIN SGLC";

  return (
    <>
      <AdmissionPage/>
    </>
  );
};

export default Admission;
