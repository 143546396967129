import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { ExamScheduleForm, DeleteConfirmationModal } from "molecules"; 
import { getExamSchedule, updateExamSchedule, deleteExamSchedule } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

export const ExamSchedulePage = () => {

  const [ title, setTitle ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

  const dispatch = useDispatch();
  const { examSchedule } = useSelector(state => state.app);

  useEffect(()=>{
    if(title) dispatch(getExamSchedule(page, title))
  }, [title, page]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (
          <Flex
            style={{gap: "1rem"}}
          >
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={params.row.hide ? "danger.500" : "success.500"}
              onClick={() => {                
                dispatch(updateExamSchedule({
                  id: params.row.id,
                  visibility: !params.row.hide
                }))
              }}
            >
              <AiFillEye />            
            </Flex>
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color="danger.500"
              onClick={() => {
                setForDelete({
                  id: params.row.id,
                  name: params.row.title
                });
                setDeleteModalOpen(true);
              }}
            >
              <MdDeleteOutline />            
            </Flex>
            </Flex>        
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },    
    { field: "href", headerName: "Link", minWidth: 150, flex: 0.5,
      renderCell: (params) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            color="primary.500"
            cursor="pointer"
            as="a"
            href={generatePublicURL(params.row.href)}
            target="_blank"
            rel="noreferrer"
          >
            View
          </Flex>
        );
      }
     },
  ]

  const rows = [];
  examSchedule?.examSchedule?.forEach(e => {    
    rows.push({
      id: e.id,
      title: e.title,      
      hide: e.hide,
      href: e.href,
    });
  });

  return (
    <Box>
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name,
            module: "Exam Schedule",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => {
            dispatch(deleteExamSchedule(forDelete?.id))
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
        />
      }          
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Exam Schedule
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={title}
            icon={<BiSearchAlt2/>}
            setValue={setTitle}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <ExamScheduleForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      {examSchedule?.resultPerPage < examSchedule?.totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={examSchedule?.resultPerPage}
          totalItemsCount={examSchedule?.totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {examSchedule?.totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {examSchedule?.totalCount}
      </Text>}   
      </Box>      
  </Box>
  )
}