import { Formik, Form } from "formik";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Input, Text } from "atoms";
import { createAdmission, updateAdmission } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  link: Yup.string()
    .required("Link Required"),
});

export const AdmissionForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        title: values.title,
        link: values.link,
      }      
      if(values.eligibility) form.eligibility = values.eligibility;
      if(values.duration) form.duration = values.duration;
      if(values.startDate) form.startDate = values.startDate;
      if(values.lastDate) form.lastDate = values.lastDate;
      dispatch(createAdmission(form));      
    }else if(type === "update"){
      const form = {
        title: values.title,
        link: values.link,
        eligibility: values.eligibility,
        duration: values.duration,
        startDate: values.startDate,
        lastDate: values.lastDate,
        hide: values.hide,        
      }            
      dispatch(updateAdmission(data?.id, form));
      setFold(true);
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >     
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>                       
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            link: data?.link || "",
            eligibility: data?.eligibility || "",
            duration: data?.duration || "",
            startDate: data?.startDate ? isoDateToHtmlInputDate(data.startDate) : "",
            lastDate: data?.lastDate ? isoDateToHtmlInputDate(data.lastDate) : "",
            hide: type === "update" ? data?.hide : true,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box flexGrow="1">
                    <Input   
                      label="Title"
                      name="title"
                      type="text"
                      placeholder="Title*"                  
                      id="title"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.title ? "danger.100" : "primary.500"}
                      touched={touched.title}
                      errors={errors.title}                        
                    /> 
                  </Box>
                  {type==="update" && <Box>
                    <FormControlLabel
                      control={
                        <Switch                          
                          checked={values.hide}
                          onChange={(e) => {
                            setFieldValue("hide", e.target.checked);
                          }}
                          name="hide"
                          color="primary"
                        />
                      }
                      label="Hide Form"
                    />
                  </Box>}
                </Flex>
                <Box width="100%">
                  <Input   
                    label="Apply Link"
                    name="link"
                    type="text"
                    placeholder="Apply Link*"                  
                    id="link"
                    icon={<HiOutlineExternalLink />}
                    iconColor={errors.link ? "danger.100" : "primary.500"}
                    touched={touched.link}
                    errors={errors.link}                        
                  /> 
                </Box>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box width="100%">
                    <Input   
                      label="Start Date"
                      name="startDate"
                      type="date"
                      placeholder="Start Date*"                  
                      id="startDate"
                      icon={<BsCalendarDate />}
                      iconColor={errors.startDate ? "danger.100" : "primary.500"}
                      touched={touched.startDate}
                      errors={errors.startDate}                        
                    /> 
                  </Box>
                  <Box width="100%">
                    <Input   
                      label="Last Date"
                      name="lastDate"
                      type="date"
                      placeholder="Last Date*"                  
                      id="lastDate"
                      icon={<BsCalendarDate />}
                      iconColor={errors.lastDate ? "danger.100" : "primary.500"}
                      touched={touched.lastDate}
                      errors={errors.lastDate}                        
                    /> 
                  </Box>
                </Flex>  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box width="100%">
                    <Input   
                      label="Eligibility"
                      name="eligibility"
                      type="text"
                      placeholder="Eligibility*"                  
                      id="eligibility"
                      icon={<AiOutlineInfoCircle />}
                      iconColor={errors.eligibility ? "danger.100" : "primary.500"}
                      touched={touched.eligibility}
                      errors={errors.eligibility}                        
                    /> 
                  </Box>
                  <Box width="100%">
                    <Input   
                      label="Duration"
                      name="duration"
                      type="text"
                      placeholder="Duration*"                  
                      id="duration"
                      icon={<BiTimeFive />}
                      iconColor={errors.duration ? "danger.100" : "primary.500"}
                      touched={touched.duration}
                      errors={errors.duration}                        
                    /> 
                  </Box>
                </Flex>                
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}