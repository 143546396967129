import { useState } from "react";
import { FieldArray, Formik, Form } from "formik";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { MdAccessTime, MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Input, InputSelect, Loader, Text } from "atoms";
import { createEvent } from "redux/actions";
import { isoDateToHtmlInputDate, upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  description: Yup.string()
    .required("Description Required"),
  eventDate: Yup.string()
    .required("Event Date Required"),
});

export const EventForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  

  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        title: values.title,
        desc: values.description,
        eventDate: values.eventDate,
      }
      setLocalLoading(true);
      let src = [];
      for(let i = 0; i < values.eventSrc?.length; i++){
        if(values.eventSrc[i].type === "file"){
          let url = await upload(values.eventSrc[i].file);
          src.push({
            title: values.eventSrc[i].title,
            href: url,
          });
        }else{
          src.push({
            title: values.eventSrc[i].title,
            href: values.eventSrc[i].href,
          });
        }
      }
      form.eventSrc = src;
      setLocalLoading(false);
      dispatch(createEvent(form));
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <> 
      {localLoading && <Loader/>}     
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>                          
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            description: data?.description || "",
            eventDate: data?.eventDate ? isoDateToHtmlInputDate(data?.eventDate) : "",
            eventSrc: data?.eventSrc || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                > 
                  <Box width="100%">
                    <Input   
                      label="Title"               
                      name="title"
                      type="text"
                      placeholder="Title*"                  
                      id="title"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.title ? "danger.100" : "primary.500"}
                      touched={touched.title}
                      errors={errors.title}                        
                    /> 
                  </Box>                  
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                     
                  <Box width="100%">
                    <Input   
                      label="Description"               
                      name="description"
                      type="text"
                      placeholder="Description*"                  
                      id="description"
                      icon={<HiOutlineLightningBolt />}
                      iconColor={errors.description ? "danger.100" : "primary.500"}
                      touched={touched.description}
                      errors={errors.description}  
                      textArea
                      rows="5"
                      lessMB
                    /> 
                  </Box>
                  <Box width="100%">
                    <Box width="100%">
                      <Input   
                        label="Event Date"               
                        name="eventDate"
                        type="date"
                        placeholder="Event Date*"                  
                        id="eventDate"
                        icon={<MdAccessTime />}
                        iconColor={errors.eventDate ? "danger.100" : "primary.500"}
                        touched={touched.eventDate}
                        errors={errors.eventDate}     
                        lessMB                       
                      /> 
                    </Box>
                    <Button
                      type="button"
                      variant="warning"
                      p="1rem 2rem"
                      borderRadius="0.5rem"
                      onClick={() => setFieldValue(
                        "eventSrc",
                        [...values.eventSrc, { title: "", href: "", type: "", file: null }]
                      )}
                    >
                      Add Source
                    </Button>
                  </Box>                  
                </Flex>
                <FieldArray
                  name="eventSrc"
                  render={(arrayHelpers) => (
                    <Box mt="1rem">
                      {values.eventSrc?.map((item, index) => (
                        <Flex 
                          key={index}
                          alignItems="center"
                          style={{gap: "1rem"}}
                        >    
                          <Box>                          
                            <Input
                              label={`Source Title ${index + 1}`}
                              name={`eventSrc.${index}.title`}
                              type="text"
                              placeholder="Source Title"
                              id={`eventSrc.${index}.title`}
                              icon={<MdOutlineTitle />}
                              iconColor={errors.eventSrc ? "danger.100" : "primary.100"}
                              touched={touched.eventSrc}
                              errors={errors.eventSrc}  
                              lessMB                          
                            />
                          </Box>
                          <Box mt="1rem">                          
                            <InputSelect
                              // label={`Source Type ${index + 1}`}
                              name={`eventSrc.${index}.type`}
                              type="text"
                              placeholder="Source Type"
                              id={`eventSrc.${index}.type`}                                                            
                              touched={touched.eventSrc}
                              errors={errors.eventSrc}                            
                              value={values.eventSrc[index].type}
                              onChange={(e) => setFieldValue(`eventSrc.${index}.type`, e.target.value)}
                              options={[
                                { value: "link", label: "Link" },
                                { value: "file", label: "File" },
                              ]}                              
                            />
                          </Box>  
                          {values.eventSrc[index].type === "link" && <Box>                          
                            <Input
                              label={`Source URL ${index + 1}`}
                              name={`eventSrc.${index}.href`}
                              type="text"
                              placeholder="Source URL"
                              id={`eventSrc.${index}.href`}
                              icon={<FiExternalLink />}
                              iconColor={errors.eventSrc ? "danger.100" : "primary.100"}
                              touched={touched.eventSrc}
                              errors={errors.eventSrc}  
                              lessMB                          
                            />
                          </Box>} 
                          {values.eventSrc[index].type === "file" && <Box>                          
                            <input
                              type="file"
                              name={`eventSrc.${index}.file`}
                              id={`eventSrc.${index}.file`}
                              onChange={(e) => {if(e.target.files[0]){
                                setFieldValue(`eventSrc.${index}.file`, e.target.files[0])
                              }}}                              
                            />
                          </Box>}                                                                          
                          <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            mt="1rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>                         
                        </Flex>
                      ))}
                    </Box>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}