import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Loader } from "atoms";
import { appConstants } from "redux/constants";
import Dashboard from "pages/dashboard";
import Tools from "pages/tools";
import NotFound from "pages/not-found";
import Academic from "pages/academic";
import Admission from "pages/admission";
import CW from "pages/cw";
import Event from "pages/event";
import ExamSchedule from "pages/exam-schedule";
import Syllabus from "pages/syllabus";
import Result from "pages/result";
import Faculty from "pages/faculty";
import Gallery from "pages/gallery";
import News from "pages/news";
import Review from "pages/review";
import ImpLink from "pages/imp-link";

export const AppRoutes = () => {
  
  const dispatch = useDispatch();
  const { initLoading, error, message, loading } = useSelector(state => state.app);  

  useEffect(()=>{
    if(error){
      toast.error(error)
      dispatch({type: appConstants.RESET_APP})
    }
    if(message){
      toast.success(message)
      dispatch({type: appConstants.RESET_APP})
    }
  }, [error, message, dispatch]);
  
  if(initLoading) return <Loader />

  return (
    <Suspense fallback={<Loader />}>      
      {loading && <Loader />}
      <Routes>
        <Route path="/" exact element={<Navigate to="/dashboard" replace />} />        
        <Route path="/dashboard" exact element={<Dashboard />} />  
        <Route path="/tools" exact element={<Tools />} />
        <Route path="/academic" exact element={<Academic />} />
        <Route path="/admission" exact element={<Admission />} />
        <Route path="/conference" exact element={<CW />} />
        <Route path="/events" exact element={<Event />} />
        <Route path="/exam-schedule" exact element={<ExamSchedule />} />
        <Route path="/syllabus" exact element={<Syllabus />} />
        <Route path="/result" exact element={<Result />} />
        <Route path="/faculty" exact element={<Faculty />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/news" exact element={<News />} />
        <Route path="/testimonial" element={<Review />} />
        <Route path="/important-links" element={<ImpLink />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};