import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, Image, InputDebounce, Text } from "atoms";
import { GalleryForm } from "molecules";
import { getGallery, updateGallery } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

export const GalleryPage = () => {

  const [ page, setPage ] = useState(1);
  const [ title, setTitle ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { gallery } = useSelector(state => state.app);

  useEffect(()=>{
    if(title) dispatch(getGallery(page, title))
  }, [page, title]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color={params.row.hide ? "danger.500" : "success.500"}
            onClick={() => {                
              dispatch(updateGallery({
                id: params.row.id,
                visibility: !params.row.hide
              }))
            }}
          >
            <AiFillEye />            
          </Flex>                               
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "title", headerName: "Title", minWidth: 200, flex: 1,
      renderCell: (params) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="flex-start"            
            style={{gap: "1rem"}}
          >
            <Box
              width="4rem"
              height="4rem"              
            >
              <Image
                src={generatePublicURL(params.row.thumbnail)}
                alt={params.row.title}
                layout="fill"
                objectFit="cover"
                width="100%"
                height="100%"
              />
            </Box>
            <Text
              fontSize="1.4rem"            
              color="dark.500"            
            >
              {params.row.title}
            </Text>
          </Flex>
        );
      }
    },
    {field: "images", headerName: "Images", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  gallery?.albums?.forEach(a => {    
    rows.push({
      id: a.id,
      title: a.title,
      thumbnail: a.thumbnail,
      hide: a.hide,
      images: a?.galleryImages?.length
    });
  });

  return (
    <Box>          
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage albums
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={title}
            icon={<BiSearchAlt2/>}
            setValue={setTitle}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <GalleryForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box> 
      {gallery?.resultPerPage < gallery?.totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={gallery?.resultPerPage}
          totalItemsCount={gallery?.totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {gallery?.totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {gallery?.totalCount}
      </Text>}       
  </Box>
  )
}