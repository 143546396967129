import { FacultyPage } from "templates";

export const Faculty = () => {

  document.title = "Faculty | ADMIN SGLC";

  return (
    <>
      <FacultyPage/>
    </>
  );
};

export default Faculty;
