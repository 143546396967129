import React from "react";
import { DebounceInput } from "react-debounce-input";

import Box from "../Box";
import Flex from "../Flex";
import Text from "../Text";

export const InputDebounce = ({
  id,
  touched,
  errors,
  iconColor,
  icon,
  placeholder,
  label,
  value,
  setValue,
  debounceTimeout=500,
  minLength=3,
  lessMB,
  ...props 
}) => {
  return (
    <Box>
      {label && <Text
        as="label"
        htmlFor={id}
        fontSize="1.2rem"
        fontWeight="300"
        color="grey.300"          
      >
        {label}
      </Text>}
      <Box mb={lessMB ? "0.8rem" : "2rem"} mt={label ? "-0.8rem" : "unset"}>        
        {icon && <Flex
          position="absolute"
          left="1rem"          
          height="4rem"
          alignItems="center"
          justifyContent="center"
          fontSize="1.4rem"           
          color={touched && !errors ? "accent.500" : iconColor}                   
        >
          {icon}
        </Flex>}
        <DebounceInput
          minLength={minLength}
          debounceTimeout={debounceTimeout}
          onChange={e => setValue(e.target.value)} 
          value={value}
          placeholder={placeholder}
          {...props}
        />  
      </Box>                    
    </Box>
  )
}
