import { useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillEye } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { CWForm } from "molecules"; 
import { getCWs, updateCW } from "redux/actions";
import Pagination from "react-js-pagination";

export const CWPage = () => {

  const [ title, setTitle ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);
  
  const dispatch = useDispatch();
  const { conference } = useSelector(state => state.app);

  useEffect(()=>{
    if(title) dispatch(getCWs(page, title))
  }, [title, page]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (
          <Flex
            style={{gap: "1rem"}}
          >
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={params.row.active ? "success.500" : "danger.500"}              
              onClick={() => {
                dispatch(updateCW({
                  id: params.row.id,
                  visibility: !params.row.active
                }));
              }}
            >
              <AiFillEye />            
            </Flex>           
          </Flex>          
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },    
    {field: "active", headerName: "Active", minWidth: 130, flex: 0.5, align: "center", headerAlign: "center", disableColumnMenu: true, sortable: false,
      renderCell: (params) => (
        <Box
          fontSize="1.8rem"
          color={params.row.active ? "success.500" : "danger.500"}
        >
          {params.row.active ? <AiFillCheckCircle/> : <AiFillCloseCircle/>}
        </Box>
      )
    },
  ]

  const rows = [];
  conference?.cw?.forEach(cw => {    
    rows.push({
      id: cw.id,
      title: cw.title,      
      active: !cw.hide
    });
  });

  return (
    <Box>    
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage Conference & Workshop
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={title}
            icon={<BiSearchAlt2/>}
            setValue={setTitle}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <CWForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      {conference?.resultPerPage < conference?.totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={conference?.resultPerPage}
          totalItemsCount={conference?.totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {conference?.totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {conference?.totalCount}
      </Text>}   
      </Box>      
  </Box>
  )
}