import { useState } from "react";
import { FieldArray, Formik, Form } from "formik";
import { FiExternalLink } from "react-icons/fi";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Image, Input, InputSelect, Loader, Text } from "atoms";
import { createNews } from "redux/actions";
import { upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  description: Yup.string()
    .required("Description Required"),  
});

const tags = [
  { value: "news", label: "News" },
  { value: "announcements", label: "Announcements" },
  { value: "media", label: "Media" },
  { value: "tender", label: "Tender" },
  { value: "press release", label: "Press Release" },
  { value: "students", label: "Students" },
  { value: "faculty", label: "Faculty" },
]

export const NewsForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const [ image, setImage ] = useState(null);
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){      
      if(!image) toast.error("Image Required");
      setLocalLoading(true);
      let url = await upload(image);
      const form = {
        title: values.title,
        desc: values.description,
        image: url,
      }
      let src = [];
      for(let i = 0; i < values.newsSrc?.length; i++){
        if(values.newsSrc[i].type === "file"){
          let url = await upload(values.newsSrc[i].file);
          src.push({
            title: values.newsSrc[i].title,
            href: url,
          });
        }else{
          src.push({
            title: values.newsSrc[i].title,
            href: values.newsSrc[i].href,
          });
        }
      }      
      form.newsSrc = src;
      if(values.tag) form.tag = values.tag;
      if(values.showPopup) form.showPopup = values.showPopup;
      setLocalLoading(false);
      dispatch(createNews(form));
    }    
    setImage(null);
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>   
      {localLoading && <Loader/>}   
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>                          
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            description: data?.description || "",
            tag: data?.tag || "",
            showPopup: data?.showPopup || false,
            newsSrc: data?.newsSrc || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex>
                  <Flex                    
                    justifyContent="space-between"
                    style={{gap: "2rem"}}
                    flex="1"
                  > 
                    <Box flexGrow="1">
                      <Input   
                        label="Title"               
                        name="title"
                        type="text"
                        placeholder="Title*"                  
                        id="title"
                        icon={<MdOutlineTitle />}
                        iconColor={errors.title ? "danger.100" : "primary.500"}
                        touched={touched.title}
                        errors={errors.title}                        
                      /> 
                    </Box> 
                    <Box mt="2rem">
                      <FormControlLabel
                        control={
                          <Switch                          
                            checked={values.showPopup}
                            onChange={(e) => {
                              setFieldValue("showPopup", e.target.checked);
                            }}
                            name="showPopup"
                            color="secondary"
                          />
                        }
                        label="Show Popup?"
                      />
                    </Box>                 
                  </Flex>
                  <Flex
                    mt="2rem"
                    flex="0.5"
                    alignItems="center"
                    flexDirection="column"
                  >                        
                    {image && <Box
                      width="10rem"
                      height="10rem"
                      mb="1rem"
                    >
                      <Image
                        src={URL.createObjectURL(image)}
                        width="100%"
                        height="100%"                        
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt="image"
                      />
                    </Box>}
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"
                      height="4rem"                      
                      type="button"
                      p="0.5rem 1rem"                      
                      width="fit-content"
                      borderRadius="0.5rem"                      
                    >
                      Pick Image
                    </Button>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"                      
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                  </Flex>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                     
                  <Box width="100%">
                    <Input   
                      label="Description"               
                      name="description"
                      type="text"
                      placeholder="Description*"                  
                      id="description"
                      icon={<HiOutlineLightningBolt />}
                      iconColor={errors.description ? "danger.100" : "primary.500"}
                      touched={touched.description}
                      errors={errors.description}  
                      textArea
                      rows="5"
                      lessMB
                    /> 
                  </Box>
                  <Box width="100%">
                    <Box width="100%">
                      <InputSelect   
                        label="Tag"               
                        name="tag"                        
                        placeholder="Select Tag"                  
                        id="tag"                        
                        touched={touched.tag}
                        errors={errors.tag}     
                        value={values.tag}
                        setValue={setFieldValue}
                        fieldName={"tag"}
                        formik
                        options={tags}
                      /> 
                    </Box>
                    <Button
                      mt="1rem"
                      type="button"
                      variant="warning"
                      p="1rem 2rem"
                      borderRadius="0.5rem"
                      onClick={() => setFieldValue(
                        "newsSrc",
                        [...values.newsSrc, { title: "", href: "", type: "", file: null }]
                      )}
                    >
                      Add Source
                    </Button>
                  </Box>                  
                </Flex>
                <FieldArray
                  name="newsSrc"
                  render={(arrayHelpers) => (
                    <Box mt="1rem">
                      {values.newsSrc?.map((item, index) => (
                        <Flex 
                          key={index}
                          alignItems="center"
                          style={{gap: "1rem"}}
                        >    
                          <Box>                          
                            <Input
                              label={`Source Title ${index + 1}`}
                              name={`newsSrc.${index}.title`}
                              type="text"
                              placeholder="Source Title"
                              id={`newsSrc.${index}.title`}
                              icon={<MdOutlineTitle />}
                              iconColor={errors.newsSrc ? "danger.100" : "primary.100"}
                              touched={touched.newsSrc}
                              errors={errors.newsSrc}  
                              lessMB                          
                            />
                          </Box>
                          <Box mt="1rem">                          
                            <InputSelect
                              // label={`Source Type ${index + 1}`}
                              name={`newsSrc.${index}.type`}
                              type="text"
                              placeholder="Source Type"
                              id={`newsSrc.${index}.type`}                                                            
                              touched={touched.newsSrc}
                              errors={errors.newsSrc}                            
                              value={values.newsSrc[index].type}
                              onChange={(e) => setFieldValue(`newsSrc.${index}.type`, e.target.value)}
                              options={[
                                { value: "link", label: "Link" },
                                { value: "file", label: "File" },
                              ]}                              
                            />
                          </Box>  
                          {values.newsSrc[index].type === "link" && <Box>                          
                            <Input
                              label={`Source URL ${index + 1}`}
                              name={`newsSrc.${index}.href`}
                              type="text"
                              placeholder="Source URL"
                              id={`newsSrc.${index}.href`}
                              icon={<FiExternalLink />}
                              iconColor={errors.newsSrc ? "danger.100" : "primary.100"}
                              touched={touched.newsSrc}
                              errors={errors.newsSrc}  
                              lessMB                          
                            />
                          </Box>} 
                          {values.newsSrc[index].type === "file" && <Box>                          
                            <input
                              type="file"
                              name={`newsSrc.${index}.file`}
                              id={`newsSrc.${index}.file`}
                              onChange={(e) => {if(e.target.files[0]){
                                setFieldValue(`newsSrc.${index}.file`, e.target.files[0])
                              }}}                              
                            />
                          </Box>}                                                                          
                          <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            mt="1rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>                         
                        </Flex>
                      ))}
                    </Box>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}