import { SyllabusPage } from "templates";

export const Syllabus = () => {

  document.title = "Syllabus | ADMIN SGLC";

  return (
    <>
      <SyllabusPage/>
    </>
  );
};

export default Syllabus;
