import { appConstants } from "redux/constants";
import Axios from "utils/axios";

export const getAcademics = (name) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_ACADAMIC_REQUEST })
      let URL = `/admin/academics`;   
      if(name) URL += `?name=${name}`;      
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ACADAMIC_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ACADAMIC_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createAcademic = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_ACADAMIC_REQUEST })
      let URL = `/academics`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_ACADAMIC_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_ACADAMIC_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createAcademicCourse = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_ACADAMIC_COURSE_REQUEST })
      let URL = `/academics/course`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_ACADAMIC_COURSE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_ACADAMIC_COURSE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateAcademicCourse = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_ACADAMIC_COURSE_REQUEST })
      let URL = `/academics/course`;         
      const { data } = await Axios.put(URL, form);
      dispatch({ 
        type: appConstants.UPDATE_ACADAMIC_COURSE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_ACADAMIC_COURSE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getAdmissions = (title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_ADMISSION_REQUEST })
      let URL = `/admin/admissions`;   
      if(title) URL += `?title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ADMISSION_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ADMISSION_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createAdmission = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_ADMISSION_REQUEST })
      let URL = `/admissions`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_ADMISSION_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_ADMISSION_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateAdmission = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_ADMISSION_REQUEST })
      let URL = `/admissions/${id}`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_ADMISSION_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_ADMISSION_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteAdmission = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_ADMISSION_REQUEST })
      let URL = `/admissions/${id}`;         
      const { data } = await Axios.delete(URL, form);                  
      dispatch({ 
        type: appConstants.DELETE_ADMISSION_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_ADMISSION_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getCWs = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_CONFERENCE_REQUEST })
      let URL = `/admin/conference-workshop?page=${page}`;   
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_CONFERENCE_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_CONFERENCE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createCW = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_CONFERENCE_REQUEST })
      let URL = `/cw`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_CONFERENCE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_CONFERENCE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateCW = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_CONFERENCE_REQUEST })
      let URL = `/cw`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_CONFERENCE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_CONFERENCE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getEvents = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_EVENT_REQUEST })
      let URL = `/admin/events?page=${page}`;   
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EVENT_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createEvent = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_EVENT_REQUEST })
      let URL = `/events`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_EVENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateEvent = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_EVENT_REQUEST })
      let URL = `/events`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_EVENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){                 
      dispatch({
        type: appConstants.UPDATE_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteEvent = (id) => {
  return async (dispatch) => {    
    try{      
      dispatch({ type: appConstants.DELETE_EVENT_REQUEST })
      let URL = `/events/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_EVENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getExamSchedule = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_EXAM_SCHEDULE_REQUEST })
      let URL = `/admin/exam-schedule?page=${page}`;   
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EXAM_SCHEDULE_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createExamSchedule = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_EXAM_SCHEDULE_REQUEST })
      let URL = `/exam-schedule`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_EXAM_SCHEDULE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateExamSchedule = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_EXAM_SCHEDULE_REQUEST })
      let URL = `/exam-schedule`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_EXAM_SCHEDULE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteExamSchedule = (id) => {
  return async (dispatch) => {    
    try{      
      dispatch({ type: appConstants.DELETE_EXAM_SCHEDULE_REQUEST })
      let URL = `/exam-schedule/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_EXAM_SCHEDULE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getFaculty = (name) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_FACULTY_REQUEST })
      let URL = `/admin/faculties`; 
      if(name) URL += `?name=${name}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_FACULTY_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_FACULTY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createFaculty = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_FACULTY_REQUEST })
      let URL = `/faculties`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_FACULTY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_FACULTY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateFaculty = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_FACULTY_REQUEST })
      let URL = `/faculties/${id}`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_FACULTY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_FACULTY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteFaculty = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_FACULTY_REQUEST })
      let URL = `/faculties/${id}`;         
      const { data } = await Axios.delete(URL, form);                  
      dispatch({ 
        type: appConstants.DELETE_FACULTY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_FACULTY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getGallery = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_GALLERY_REQUEST })
      let URL = `/admin/albums?page=${page}`; 
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_GALLERY_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_GALLERY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createGallery = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_GALLERY_REQUEST })
      let URL = `/gallery`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_GALLERY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_GALLERY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateGallery = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_GALLERY_REQUEST })
      let URL = `/gallery`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_GALLERY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_GALLERY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getImpLinks = (title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_IMP_LINKS_REQUEST })
      let URL = `/admin/imp-links`; 
      if(title) URL += `?title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_IMP_LINKS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_IMP_LINKS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createImpLink = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_IMP_LINKS_REQUEST })
      let URL = `/imp-links`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_IMP_LINKS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_IMP_LINKS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createImpUrl = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_IMP_URL_REQUEST })
      let URL = `/imp-links/url`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_IMP_URL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_IMP_URL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateImpUrl = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_IMP_URL_REQUEST })
      let URL = `/imp-links/url`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_IMP_URL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_IMP_URL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const hideImpUrl = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.HIDE_IMP_URL_REQUEST })
      let URL = `/imp-links/url/hide`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.HIDE_IMP_URL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.HIDE_IMP_URL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getNews = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_NEWS_REQUEST })
      let URL = `/admin/news?page=${page}`;   
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_NEWS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createNews = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_NEWS_REQUEST })
      let URL = `/news`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_NEWS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateNews = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_NEWS_REQUEST })
      let URL = `/news`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_NEWS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteNews = (id) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_NEWS_REQUEST })
      let URL = `/news/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_NEWS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getResults = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_RESULT_REQUEST })
      let URL = `/admin/results?page=${page}`;   
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_RESULT_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createResult = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_RESULT_REQUEST })
      let URL = `/results`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_RESULT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateResult = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_RESULT_REQUEST })
      let URL = `/results`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_RESULT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteResult = (id) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_RESULT_REQUEST })
      let URL = `/results/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_RESULT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getSyllabus = (page, title) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_SYLLABUS_REQUEST })
      let URL = `/admin/syllabus?page=${page}`;   
      if(title) URL += `&title=${title}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_SYLLABUS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createSyllabus = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_SYLLABUS_REQUEST })
      let URL = `/syllabus`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateSyllabus = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_SYLLABUS_REQUEST })
      let URL = `/syllabus`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteSyllabus = (id) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_SYLLABUS_REQUEST })
      let URL = `/syllabus/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getReviews = (page, username) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_REVIEW_REQUEST })
      let URL = `/admin/testimonials?page=${page}`;   
      if(username) URL += `&username=${username}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_REVIEW_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_REVIEW_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createReview = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_REVIEW_REQUEST })
      let URL = `/testimonials`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_REVIEW_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_REVIEW_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateReview = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_REVIEW_REQUEST })
      let URL = `/testimonials`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_REVIEW_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_REVIEW_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}