import { BiNews } from 'react-icons/bi';
import { BsFillCalendarDateFill } from 'react-icons/bs';
import { FaSchool, FaExternalLinkAlt } from 'react-icons/fa';
import { GiVideoConference, GiTeacher } from 'react-icons/gi';
import { IoMdSchool } from 'react-icons/io';
import { MdPictureAsPdf, MdOutlineDashboard, MdOutlineReviews } from "react-icons/md";
import { RiGalleryFill } from 'react-icons/ri';
import { SiCoursera } from "react-icons/si";

export const sideNav = [
  {
    Icon: <MdOutlineDashboard/>,
    title: "Dashboard",
    to: "/dashboard",
    description: "View Admin dashboard",
  },
  {
    Icon: <SiCoursera/>,
    title: "Academic",
    to: "/academic",
    description: "Manage Academic",
  },
  {
    Icon: <IoMdSchool/>,
    title: "Admission",
    to: "/admission",
    description: "Manage Admission",
  },
  {
    Icon: <GiVideoConference/>,
    title: "Conference & Workshop",
    to: "/conference",
    description: "Manage Conference & Workshop",
  },
  {
    Icon: <BsFillCalendarDateFill/>,
    title: "Events",
    to: "/events",
    description: "Manage Events",
  },
  {
    Icon: <FaSchool/>,
    title: "Exam Schedule",
    to: "/exam-schedule",
    description: "Manage Exam Schedule",
  },
  {
    Icon: <GiTeacher/>,
    title: "Faculty",
    to: "/faculty",
    description: "Manage Faculty",
  },
  {
    Icon: <RiGalleryFill/>,
    title: "Gallery",
    to: "/gallery",
    description: "Manage Gallery",
  },
  {
    Icon: <FaExternalLinkAlt/>,
    title: "Important Links",
    to: "/important-links",
    description: "Manage Important Links",
  },
  {
    Icon: <BiNews/>,
    title: "News",
    to: "/news",
    description: "Manage News",
  },
  {
    Icon: <IoMdSchool/>,
    title: "Result",
    to: "/result",
    description: "Manage Result",
  },
  {
    Icon: <MdPictureAsPdf/>,
    title: "Syllabus",
    to: "/syllabus",
    description: "Manage Syllabus",
  },
  {
    Icon: <MdOutlineReviews/>,
    title: "Testimonial",
    to: "/testimonial",
    description: "Manage Testimonial",
  }
]