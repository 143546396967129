import { useState } from "react";
import { Formik, Form } from "formik";
import { AiFillCrown, AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { MdFolderSpecial, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Image, Input, Loader, Text } from "atoms";
import { createFaculty, updateFaculty } from "redux/actions";
import { generatePublicURL, upload } from "utils/utilities";
import { IoMdSchool } from "react-icons/io";

const validationSchema = Yup.object({
  name: Yup.string()    
    .required("Name Required"),
  designation: Yup.string()
    .required("Designation Required"),
  email: Yup.string()
    .email("Invalid Email")
    .required("Email Required"),
  index: Yup.number()
    .required("Index Required"),
});

export const FacultyForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const [ image, setImage ] = useState(null);
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      if(!image) toast.error("Please select an image");
      const form = new FormData();
      form.append("image", image);
      form.append("name", values.name);
      form.append("designation", values.designation);
      form.append("email", values.email);
      form.append("index", values.index);      
      if(values.qualification) form.append("qualification", values.qualification);
      if(values.specification) form.append("specification", values.specification);
      if(values.phone) form.append("phone", values.phone);
      setLocalLoading(true);
      if(values.profile){
        let url = await upload(values.profile);
        form.append("profileUrl", url);
      }
      setLocalLoading(false);
      dispatch(createFaculty(form));      
    }else if(type === "update"){
      const form = new FormData();
      form.append("name", values.name);
      form.append("designation", values.designation);
      form.append("email", values.email);
      form.append("index", values.index);
      form.append("hide", values.hide);
      form.append("qualification", values.qualification);
      form.append("specification", values.specification);
      form.append("phone", values.phone);
      setLocalLoading(true);
      if(values.profile){
        let url = await upload(values.profile);
        form.append("profileUrl", url);
      }
      if(image) form.append("image", image);
      setLocalLoading(false);
      dispatch(updateFaculty(data?.id, form));
      setFold(true);
    }    
    setImage(null);
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>    
      {localLoading && <Loader/>}  
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >     
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>                       
        {!fold && <Formik
          initialValues={{
            name: type === "update" ? data?.name : "",
            designation: type === "update" ? data?.designation : "",
            email: type === "update" ? data?.email : "",
            index: type === "update" ? data?.index : "",
            qualification: type === "update" ? data?.qualification : "",
            specification: type === "update" ? data?.specification : "",
            phone: type === "update" ? data?.phone : "",
            hide: type === "update" ? data?.hide : false,
            image: type === "update" ? data?.image : null,
            profile: null,
            profileUrl: type === "update" ? data?.profileUrl : null,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  style={{gap: "1.5rem"}}
                >
                  <Box                    
                    flex="1"
                  >    
                    <Flex
                      style={{gap: "1rem"}}
                      alignItems="center"
                    >
                      <Box flexGrow="1">
                        <Input   
                          label="Name"
                          name="name"
                          type="text"
                          placeholder="Name*"                  
                          id="name"
                          icon={<MdOutlineTitle />}
                          iconColor={errors.name ? "danger.100" : "primary.500"}
                          touched={touched.name}
                          errors={errors.name}   
                          lessMB                     
                        /> 
                      </Box>                    
                      {type==="update" && <Box>
                        <FormControlLabel
                          control={
                            <Switch                          
                              checked={values.hide}
                              onChange={(e) => {
                                setFieldValue("hide", e.target.checked);
                              }}
                              name="hide"
                              color="primary"
                            />
                          }
                          label="Hide?"
                        />
                      </Box>}
                    </Flex>               
                    <Box>
                      <Input   
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email*"                  
                        id="email"
                        icon={<AiTwotoneMail />}
                        iconColor={errors.email ? "danger.100" : "primary.500"}
                        touched={touched.email}
                        errors={errors.email} 
                        lessMB                       
                      /> 
                    </Box>
                  </Box>
                  <Flex
                    mt="2rem"
                    flex="0.5"
                    alignItems="center"
                    flexDirection="column"
                  >                        
                    {(values.image || image) && <Box
                      width="10rem"
                      height="10rem"
                      mb="1rem"
                    >
                      <Image
                        src={image ? URL.createObjectURL(image) : generatePublicURL(values.image)}
                        width="100%"
                        height="100%"                        
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt="image"
                      />
                    </Box>}
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"
                      type="button"
                      p="0.5rem 1rem"
                      height="4rem"                      
                      width="fit-content"
                      borderRadius="0.5rem"                      
                    >
                      {values.image ? "Change Image" : "Pick Image"}
                    </Button>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"                      
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                  </Flex>
                </Flex>
                <Flex
                  style={{gap: "1rem"}}
                  alignItems="center"
                >
                  <Box width="100%">
                    <Input   
                      label="Phone"
                      name="phone"
                      type="text"
                      placeholder="Phone*"                  
                      id="phone"
                      icon={<AiFillPhone />}
                      iconColor={errors.phone ? "danger.100" : "primary.500"}
                      touched={touched.phone}
                      errors={errors.phone}   
                      lessMB                     
                    /> 
                  </Box>                    
                  <Box width="100%">
                    <Input   
                      label="Designation"
                      name="designation"
                      type="text"
                      placeholder="Designation*"                  
                      id="designation"
                      icon={<AiFillCrown />}
                      iconColor={errors.designation ? "danger.100" : "primary.500"}
                      touched={touched.designation}
                      errors={errors.designation}   
                      lessMB                     
                    /> 
                  </Box> 
                </Flex> 
                <Flex
                  style={{gap: "1rem"}}
                  alignItems="center"
                >
                  <Box width="100%">
                    <Input   
                      label="Qualification"
                      name="qualification"
                      type="text"
                      placeholder="Qualification*"                  
                      id="qualification"
                      icon={<IoMdSchool />}
                      iconColor={errors.qualification ? "danger.100" : "primary.500"}
                      touched={touched.qualification}
                      errors={errors.qualification}   
                      lessMB                     
                    /> 
                  </Box>                    
                  <Box width="100%">
                    <Input   
                      label="Specification"
                      name="specification"
                      type="text"
                      placeholder="Specification*"                  
                      id="specification"
                      icon={<MdFolderSpecial />}
                      iconColor={errors.specification ? "danger.100" : "primary.500"}
                      touched={touched.specification}
                      errors={errors.specification}   
                      lessMB                     
                    /> 
                  </Box> 
                </Flex> 
                <Flex
                  style={{gap: "1rem"}}
                  alignItems="center"
                >
                  <Box width="100%">
                    <Input   
                      label="Index"
                      name="index"
                      type="number"
                      placeholder="Index*"                  
                      id="index"
                      icon={<IoMdSchool />}
                      iconColor={errors.index ? "danger.100" : "primary.500"}
                      touched={touched.index}
                      errors={errors.index}   
                      lessMB                     
                    /> 
                  </Box> 
                  <Box width="100%">
                    <input
                      type="file"
                      id="cv"
                      name="cv"
                      accept="application/pdf"
                      onChange={(e) => {
                        setFieldValue("profile", e.target.files[0]);
                      }}
                    />
                  </Box> 
                  {values.profileUrl && <Box width="100%">
                    <a 
                      href={generatePublicURL(values.profileUrl)} 
                      target="_blank" 
                      rel="noreferrer"
                    >
                      <Text
                        color="danger.100"
                        fontSize="1.2rem"
                      >
                        View Profile
                      </Text>
                    </a>
                  </Box>}
                </Flex>                                                        
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}