import { DashboardPage } from "templates";

export const Dashboard = () => {

  document.title = "Dashboard | ADMIN SGLC";

  return (
    <>
      <DashboardPage/>
    </>
  );
};

export default Dashboard;
