import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import { NewsForm, DeleteConfirmationModal } from "molecules";
import { getNews, deleteNews, updateNews } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

export const NewsPage = () => {

  const [ page, setPage ] = useState(1);
  const [ title, setTitle ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  
  const dispatch = useDispatch();
  const { news } = useSelector(state => state.app);

  useEffect(()=>{
    if(title) dispatch(getNews(page, title))
  }, [page, title]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            style={{gap: "1rem"}}
          >
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={params.row.hide ? "danger.500" : "success.500"}
              onClick={() => {                
                dispatch(updateNews({
                  id: params.row.id,
                  visibility: !params.row.hide
                }))
              }}
            >
              <AiFillEye />            
            </Flex>
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color="danger.500"
              onClick={() => {
                setForDelete({
                  id: params.row.id,
                  name: params.row.title
                });
                setDeleteModalOpen(true);
              }}
            >
              <MdDeleteOutline />            
            </Flex>
          </Flex>          
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "title", headerName: "Title", minWidth: 200, flex: 1},
    {field: "added", headerName: "Added On", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  news?.news?.forEach(n => {    
    rows.push({
      id: n.id,
      title: n.title,
      hide: n.hide,
      added: isoDateToHtmlInputDate(n.createdAt),
    });
  });

  return (
    <Box> 
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name,
            module: "News",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => {
            dispatch(deleteNews(forDelete?.id))
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
        />
      }      
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          Manage News
        </Text>
        <Box>
          <InputDebounce            
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            value={title}
            icon={<BiSearchAlt2/>}
            setValue={setTitle}            
            lessMB
          />
        </Box>                          
      </Flex>        
      <Box my="1rem">   
        <NewsForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box> 
      {news?.resultPerPage < news?.totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={news?.resultPerPage}
          totalItemsCount={news?.totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {news?.totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {news?.totalCount}
      </Text>}       
  </Box>
  )
}