import { useState } from "react";
import { Formik, Form } from "formik";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, InputSelect, Loader, Text } from "atoms";
import { createCW } from "redux/actions";
import { isoDateToHtmlInputDate, upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  description: Yup.string()
    .required("Link Required"),
  date: Yup.string()
    .required("Date Required"),
  mode: Yup.string()
    .required("Mode Required"),
  time: Yup.string()
    .required("Time Required"),
  type: Yup.string()
    .required("Type Required"),
});

export const CWForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  

  const [ localLoading, setLocalLoading ] = useState(false);
  const [ image, setImage ] = useState(null);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      if(!image) return toast.error("Image Required");
      setLocalLoading(true);
      const form = {
        title: values.title,
        description: values.description,
        date: values.date,
        mode: values.mode,
        time: String(values.time),
        image: await upload(image),
        type: values.type,       
      }      
      setLocalLoading(false);
      if(values.url) form.url = values.url;
      if(values.venue) form.venue = values.venue;
      dispatch(createCW(form));      
    }else if(type === "update"){      
    }    
    setImage(null);
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>    
      {localLoading && <Loader />}  
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={!fold && "1rem"}
        >
          <Text
            fontSize="1.6rem"
            fontWeight="bold"
            color="dark.500"
            textTransform="capitalize"
          >
            {type}
          </Text>
          <Box
            onClick={() => setFold(!fold)}
            cursor="pointer"
            fontSize="1.8rem"
          >
            {fold ? 
              <MdKeyboardArrowDown/>
            : <MdKeyboardArrowUp/>}
          </Box>
        </Flex>             
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            description: data?.description || "",
            date: data?.date ? isoDateToHtmlInputDate(data.date) : "",
            mode: data?.mode || "",
            time: data?.time || "",
            type: data?.type || "",
            url: data?.url || "",
            venue: data?.venue || "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form> 
                <Flex
                  justifyContent="space-between"
                  style={{gap: "3rem"}}
                >
                  <Box flex="1">
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      style={{gap: "1.5rem"}}
                    > 
                      <Box width="100%">
                        <Input   
                          label="Title"
                          name="title"
                          type="text"
                          placeholder="Title*"                  
                          id="title"
                          icon={<MdOutlineTitle />}
                          iconColor={errors.title ? "danger.100" : "primary.500"}
                          touched={touched.title}
                          errors={errors.title}                        
                        /> 
                      </Box>
                      <InputSelect
                        placeholder="Select Type"                    
                        name="type"
                        id="type"
                        options={[
                          { value: "workshop", label: "Workshop" },
                          { value: "conference", label: "Conference" },                      
                        ]}
                        value={values.type}
                        setValue={setFieldValue}
                        fieldName="type"
                        touched={touched.type}
                        errors={errors.type}  
                        formik                  
                      />
                    </Flex>                
                    <Flex                  
                      justifyContent="space-between"
                      style={{gap: "1.5rem"}}
                    >
                      <Box width="100%">
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          style={{gap: "1.5rem"}}
                        >
                          <Box width="100%">
                            <Input   
                              label="Date"
                              name="date"
                              type="date"
                              placeholder="Date*"                  
                              id="date"
                              icon={<BsCalendarDate />}
                              iconColor={errors.date ? "danger.100" : "primary.500"}
                              touched={touched.date}
                              errors={errors.date}                        
                            /> 
                          </Box>
                          <Box width="100%">
                            <Input   
                              label="Time"
                              name="time"
                              type="time"
                              placeholder="Time*"                  
                              id="time"
                              icon={<BiTimeFive />}
                              iconColor={errors.time ? "danger.100" : "primary.500"}
                              touched={touched.time}
                              errors={errors.time}                        
                            /> 
                          </Box>
                        </Flex>
                        <Box width="100%">
                          <InputSelect
                            placeholder="Select Mode"                    
                            name="mode"
                            id="mode"
                            options={[
                              { value: "online", label: "ONLINE" },
                              { value: "offline", label: "OFFLINE" },                      
                            ]}
                            value={values.mode}
                            setValue={setFieldValue}
                            fieldName="mode"
                            touched={touched.mode}
                            errors={errors.mode}  
                            formik                  
                          /> 
                        </Box>
                      </Box>
                      <Box width="100%">
                        <Input   
                          label="Description"
                          name="description"
                          type="text"
                          placeholder="Description*"                  
                          id="description"
                          icon={<AiOutlineInfoCircle />}
                          iconColor={errors.description ? "danger.100" : "primary.500"}
                          touched={touched.description}
                          errors={errors.description}                        
                          textArea
                          rows={5}
                        /> 
                      </Box>
                    </Flex> 
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      style={{gap: "1.5rem"}}
                    > 
                      <Box width="100%">
                        <Input   
                          label="URL"
                          name="url"
                          type="text"
                          placeholder="URL*"                  
                          id="url"
                          icon={<HiOutlineExternalLink />}
                          iconColor={errors.url ? "danger.100" : "primary.500"}
                          touched={touched.url}
                          errors={errors.url}  
                          disabled={values.mode === "offline"}                      
                        /> 
                      </Box>
                      <Box width="100%">
                        <Input   
                          label="Venue"
                          name="venue"
                          type="text"
                          placeholder="Venue*"                  
                          id="venue"
                          icon={<MdOutlineTitle />}
                          iconColor={errors.venue ? "danger.100" : "primary.500"}
                          touched={touched.venue}
                          errors={errors.venue}                        
                          disabled={values.mode === "online"}
                        /> 
                      </Box>
                    </Flex>
                  </Box> 
                  <Box mt="2rem">
                    <Button
                      type="button"
                      variant="info"
                      width="fit-content"
                      px="2rem"
                      py="1rem"                      
                      borderRadius="0.4rem"
                      as="label"
                      htmlFor="image"                      
                    >
                      Choose Image
                    </Button>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                    {image && (
                      <Box
                        width="15rem"
                        height="15rem"
                        mt="2rem"
                      >
                        <Image
                          src={URL.createObjectURL(image)}
                          alt="image"
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          borderRadius="0.4rem"
                        />
                      </Box>
                    )}
                  </Box>
                </Flex>                                              
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}